import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Loading } from "@foris/avocado-ui";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, Population } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { populationSection } from "../graphql/populationSection.query";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";
import headerCss from "../../../../common/components/Header/header.module.scss";

const GroupCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const { t } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.population);

  const variables = {
    query: populationSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "POPULATION",
    },
  };

  const headerInfo = (info: Population) => {
    const moreDetails = [
      {
        title: "Vacantes",
        content: info?.size?.toString() || "",
      },
      {
        title: "Paquetes",
        content: info?.packageCount?.toString() || "0",
      },
    ];

    const campus = info?.campus?.code || "";
    const programCode = info?.program?.code || "";
    const programName = info?.program?.name || "";
    const modality = info?.modality?.code || "";
    const shift = info?.shift?.code || "";
    const curriculum = info?.curriculum || "";
    const level = info?.level || "";

    return {
      title: `${campus}, ${programCode}, ${modality}, ${shift}, ${curriculum}, ${level} (${programName})`,
      topTitle: info?.code || "",
      subTitle: (
        <p className={headerCss.headerSubtitle_label}>
          Período: <span className={headerCss.isRegular}>{info?.term?.code}</span>
        </p>
      ),
      moreDetails,
    };
  };

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const info: any = cubeQuery.editorView.info;
        const populationInfo: Population = info.population;
        if (populationInfo) {
          const header = headerInfo(populationInfo);
          setHeaderData(header);
          const sections: ISectionData = functionsData.serializeSections(data);
          setCalendar(sections);
        } else {
          setError(true);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <Layout contextSearch>
      {headerData && (
        <Header
          breadcrumbItems={[
            {
              path: "/",
              title: t("dimension.population.breadcrumb.previous"),
            },
            {
              title: t("dimension.population.breadcrumb.current"),
            },
          ]}
          dimension={dimension.label}
          {...headerData}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension="Población" />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </Layout>
  );
};

export default GroupCalendar;
