import React, { useState, useContext, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Icon, Pager, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import { BookingContext } from "../../../context/BookingContext";
import { Types } from "../../../context/search.reducer";
import { IParams } from "@models/IParams";
import { ClassroomFilterFieldsInput, DateAvailabilityFilterInput } from "@models/ISchema";
import CardBooking from "../../../components/CardBooking/CardBooking";
import useClassroomsAvailabilities from "@modules/booking/hooks/useClassroomsAvailabilities";
import { filter, isEmpty, propOr } from "ramda";
import { Select } from "@foris/avocado-suite";
import { ContextEDH } from "@context/ContextEDH";
import { BookingClassroomOrderPayload } from "@context/base.reducer";
import css from "./results.module.scss";

const selectOptions = [
  {
    label: "Capacidad Mayor a Menor",
    value: [
      {
        field: "capacity",
        direction: "DESC",
      },
    ],
  },
  {
    label: "Capacidad Menor a Mayor",
    value: [
      {
        field: "capacity",
        direction: "ASC",
      },
    ],
  },
  {
    label: "Alfabético Edificio/Sala A > Z",
    value: [
      {
        field: "building",
        direction: "ASC",
      },
      {
        field: "name",
        direction: "ASC",
      },
    ],
  },
  {
    label: "Alfabético Edificio/Sala Z > A",
    value: [
      {
        field: "building",
        direction: "DESC",
      },
      {
        field: "name",
        direction: "DESC",
      },
    ],
  },
];

const getInitialOrderBy = (savedOrders: BookingClassroomOrderPayload = []) => {
  return selectOptions.find(option => {
    return option.value.every(order => {
      const savedOrder = savedOrders?.find(savedOrder => savedOrder.field === order.field);

      if (!savedOrder) return false;

      return order.direction === savedOrder.direction;
    });
  });
};

const ClassroomResults = () => {
  const { state: edhContext } = useContext(ContextEDH);
  const { dispatch, state } = useContext(BookingContext);
  const { scenario, origin }: IParams = useParams();
  const [{ error, loading }, results, getClassroomsAvailabilites] = useClassroomsAvailabilities(
    origin,
    scenario,
    state?.request?.editedBooking?.id,
  );
  const { preferences, requirements } = state?.search;
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(getInitialOrderBy(edhContext.base.bookingClassroomOrder));

  const calculateSessions = () => {
    // Sometimes the `state.search.dateTimes` `value` are an actual date
    // instead of a number (or raw dayId). We have to consider it to build the
    // recurrent sessions correctly.
    const getDayId = (day: any) => {
      const isNumeric = (value: string) => /^-?\d+$/.test(value);
      if (isNumeric(day?.value)) return day?.value;
      const dayId = dayjs(day?.value).day();
      return dayId === 0 ? "7" : dayId.toString();
    };

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };

    const isSameDay = (date: Date, dayId: string) => {
      const dateDayId = date.getDay().toString();
      return dateDayId === "0" ? dayId === "7" : dateDayId === dayId;
    };

    const selectedWeeks = filter(propOr(false, "selected"), state?.search?.intervals ?? []);
    const selectedDates: DateAvailabilityFilterInput[] = [];

    // recurrent booking
    if (Boolean(selectedWeeks?.length)) {
      selectedWeeks?.forEach(week => {
        const start = new Date(week?.startingDate);
        const end = new Date(week?.endingDate);

        state?.search?.dateTime?.forEach(date => {
          const dayId = getDayId(date?.day?.value);
          const currDate = new Date(start.getTime());

          // move forward one day at a time until we reach the target day
          while (!isSameDay(currDate, dayId)) {
            currDate.setDate(currDate.getDate() + 1);
          }

          // keep adding one session per week for the defined day/start/end
          // until the `end` date is reached
          while (currDate <= end) {
            const start = `${formatDate(currDate)} ${date?.start?.value?.value}:00`;
            const end = `${formatDate(currDate)} ${date?.end?.value?.value}:00`;

            selectedDates.push({ start, end });

            // move a week forward
            currDate?.setDate(currDate?.getDate() + 7);
          }
        });
      });
    }
    // uniq sessions
    else {
      state?.search?.dateTime?.forEach(date => {
        const start = `${date?.day?.value} ${date?.start?.value?.value}`;
        const end = `${date?.day?.value} ${date?.end?.value?.value}`;

        selectedDates.push({ start, end });
      });
    }

    return selectedDates;
  };

  const selectedDates: DateAvailabilityFilterInput[] = useMemo(calculateSessions, [
    state?.search?.intervals,
    state?.search?.dateTime,
  ]);

  const fields: ClassroomFilterFieldsInput[] = useMemo(() => {
    const filters: ClassroomFilterFieldsInput[] = [];

    if (preferences?.building) {
      filters.push({ buildingId: { eq: parseInt(preferences.building.value) } });
    }

    if (preferences?.classroomType) {
      filters.push({ classroomTypeId: { eq: parseInt(preferences.classroomType.value) } });
    }

    if (requirements?.people) {
      filters.push({ capacity: { value: { gte: requirements.people.count } } });
    }

    if (selectedDates.length) {
      filters.push({ dateAvailability: selectedDates });
    }

    return filters;
  }, [preferences, requirements, selectedDates, orderBy]);

  useEffect(() => {
    if (!fields) return;
    getClassroomsAvailabilites(page, fields, orderBy?.value ?? undefined);
  }, [page, fields]);

  if (loading || isEmpty(results ?? [])) return <Loading />;

  if (error) {
    console.error(error);
    return null;
  }

  const onChange = (pagePage: number) => {
    setPage(pagePage);
  };

  const pageInfoCurrent = {
    hasPreviousPage: results?.pageInfo?.hasPreviousPage,
    hasNextPage: results?.pageInfo?.hasNextPage,
    page,
    size: 10,
    total: results?.pageInfo?.total,
  };

  return (
    <section className={cx(css.cntResults, "container-row")}>
      <div
        onClick={() => dispatch({ type: Types.SetView, payload: "search" })}
        className={cx(css.cntResults_link, "col_12")}
      >
        <Icon icon="chevron-left" size={24} className={css.iconBack} />
        {`${results.pageInfo.total} Resultados`}
      </div>
      <section className={cx("container-row", css.cntResults_topPager)}>
        <Pager
          {...pageInfoCurrent}
          onPageChange={onChange}
          classname={{ global: cx(css.cntResults_pager, "col", "col_md_12") }}
        />

        <div className={css.orderWrapper}>
          <span className={css.orderWrapper_label}>Ordenar por:</span>

          <Select
            className={css.orderWrapper_select}
            options={selectOptions}
            value={orderBy}
            onChange={setOrderBy}
          />
        </div>
      </section>
      <CardBooking items={results.items} />
      <Pager
        {...pageInfoCurrent}
        onPageChange={onChange}
        classname={{ global: cx(css.cntResults_pager) }}
      />
    </section>
  );
};

export default ClassroomResults;
