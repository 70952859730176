import React, { useContext, useEffect, useState } from "react";
import { Button, CardState, Heading, Loading } from "@foris/avocado-ui";
import { LinkSelector } from "@modules/shared/selectors/Selectors";
import { Header } from "@common/components";
import { useMutation } from "react-apollo";
import { MIGRATE_GROUP_MUTATION } from "../graphql/migrateGroup.queries";
import { IParams } from "@models/IParams";
import { useHistory, useParams } from "react-router-dom";
import { CubeMutation, GroupValidationErrorUnion, Link } from "@models/ISchema";
import css from "./groupMigration.module.scss";
import { ContextEDH } from "@context/ContextEDH";

interface GroupMigrationProps {
  groupName?: string;
  groupId?: string | number;
  linkId?: string;
  onCancel?: () => void;
}

const GroupMigration = ({ groupId, groupName, onCancel, linkId }: GroupMigrationProps) => {
  const history = useHistory();
  const { state } = useContext(ContextEDH);
  const { origin, scenario, workspace }: IParams = useParams();
  const [migrateGroupMutation] = useMutation(MIGRATE_GROUP_MUTATION, {});
  const [selectedLink, setSelectedLink] = useState<Link | null>(null);
  const [isMigrationValidated, setIsMigrationValidated] = useState(false);
  const [hasClassroomCapacityError, setHasClassroomCapacityError] = useState(false);
  const [hasSizeError, setHasSizeError] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${selectedLink?.bundle?.id}`;

  const handleMigrateGroup = async () => {
    try {
      setIsloading(true);
      const data = await migrateGroupMutation({
        variables: {
          scenarioId: scenario,
          originId: origin,
          input: {
            linkId: selectedLink?.id,
            groupId,
            dryRun: !isMigrationValidated,
            skipValidations: isMigrationValidated,
          },
        },
      });

      const dataMutation: CubeMutation = data.data.cube;
      const validationErrors: Array<GroupValidationErrorUnion> =
        dataMutation.migrateGroup.validationErrors;

      if (validationErrors && validationErrors.length > 0 && !isMigrationValidated) {
        validationErrors.forEach((error: GroupValidationErrorUnion) => {
          if (error && error.__typename === "InvalidLinkChanges") {
            const hasComponentSizeError = error?.sectionChanges?.some(
              sectionChange =>
                !!sectionChange?.validationErrors?.find(
                  err => err.__typename === "InvalidComponentSize",
                ),
            );
            const hasInvalidClassroomCapacityError = error?.sessionChanges?.some(
              sessionChange =>
                !!sessionChange?.validationErrors?.find(
                  err => err.__typename === "InvalidClassroomCapacity",
                ),
            );

            setHasClassroomCapacityError(hasInvalidClassroomCapacityError);
            setHasSizeError(hasComponentSizeError);
          }
        });
      }

      if (isMigrationValidated && !!dataMutation?.migrateGroup?.commited) {
        history.replace(routeBundle);
        window.location.reload();
      }

      setIsMigrationValidated(true);
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }
  };

  useEffect(() => {
    setIsMigrationValidated(false);
    setHasClassroomCapacityError(false);
    setHasSizeError(false);
  }, [selectedLink]);

  useEffect(() => {
    if (isMigrationValidated) {
      !hasClassroomCapacityError && !hasSizeError && handleMigrateGroup();
      (hasClassroomCapacityError || hasSizeError) && setIsloading(false);
    }
  }, [isMigrationValidated]);

  return (
    <div className={css.groupMigration}>
      {isLoading && <Loading />}

      <Header topTitle="Bundle" title={groupName} />

      <div className={css.groupMigration_content}>
        <Heading type="h2" className={css.contentTitle}>
          Migrar Grupo a Liga
        </Heading>

        <LinkSelector
          label="Liga de destino"
          onSelectLink={setSelectedLink}
          isDisabled={isLoading}
          linksToAvoid={[linkId]}
          getUnrestrictedLinks={state?.base?.groupsInLinkRestriction?.enabled ? true : undefined}
        />

        {hasClassroomCapacityError && (
          <CardState
            className={css.warningCard}
            typeCard="warning"
            title="Capacidad de sala excedida"
          >
            Las vacantes del grupo exceden la capacida de los salones asignados en la liga de
            destino.
            <Button
              className={css.warningCard_action}
              variant="ghost"
              onClick={() => setHasClassroomCapacityError(false)}
            >
              Desestimar advertencia
            </Button>
          </CardState>
        )}

        {hasSizeError && (
          <CardState
            className={css.warningCard}
            typeCard="warning"
            title="Estándar Académico excedido"
          >
            Las vacantes del grupo exceden el parámetro de Vacantes Máximas para la asignatura de la
            liga.
            <Button
              className={css.warningCard_action}
              variant="ghost"
              onClick={() => setHasSizeError(false)}
            >
              Desestimar advertencia
            </Button>
          </CardState>
        )}

        <div className={css.contentActions}>
          <Button variant="outline" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            variant="solid"
            disabled={!selectedLink?.id || hasClassroomCapacityError || hasSizeError || isLoading}
            onClick={handleMigrateGroup}
          >
            Guardar cambios
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GroupMigration;
