import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Icon, SelectPagination } from "@foris/avocado-ui";
import cx from "classnames";
import { Group, Link, LinkPage } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { IOption } from "@common/components/Select/Select";
import { LINKS_QUERY } from "@common/layout/ContextSearch/contextsearch.queries";
import css from "./linkSelector.module.scss";

interface LinkOption {
  label: string;
  value: string;
}

type SelectedExternalOptions = { [key: string]: Link };

const getRegularLabel = (link: Link, isSelected: boolean) => {
  return (
    <div className={cx(css.linkLabelWrapper, isSelected && css.linkLabelWrapper__selected)}>
      <div className={css.linkOptionLabel}>
        <p className={css.linkOptionLabel_mainLabel}>
          CL: <b>{link?.code}</b>
        </p>

        <p className={css.linkOptionLabel_secondaryLabel}>
          AS: <b>{link?.course?.name}</b> | SE:{" "}
          <b>{link?.course?.curriculum?.program?.campus?.code}</b> | PE:{" "}
          <b>{link?.bundle?.term?.name}</b>
        </p>
      </div>

      {isSelected && <Icon className={css.linkLabelWrapper_icon} icon="check" size={16} />}
    </div>
  );
};

const getLinkGroupLabel = (link: Link, group: Group | null, isSelected: boolean) => {
  return (
    <div className={cx(css.linkLabelWrapper, isSelected && css.linkLabelWrapper__selected)}>
      <div className={cx(css.linkOptionLabel, css.linkOptionLabel__groups)}>
        <p className={css.linkOptionLabel_mainLabel}>
          LG: <b>{link?.code}</b>{" "}
          {group && (
            <>
              <b>|</b> GR: <b>{group?.code}</b>
            </>
          )}
        </p>

        <p className={css.linkOptionLabel_secondaryLabel}>
          Periodo: <b>{link?.bundle?.term?.name}</b> <b>|</b> Sede:{" "}
          <b>{link?.course?.curriculum?.program?.campus?.code}</b>
        </p>

        <p className={css.linkOptionLabel_secondaryLabel}>
          AS: <b>{link?.course?.name}</b>
        </p>
      </div>

      {isSelected && <Icon className={css.linkLabelWrapper_icon} icon="check" size={16} />}
    </div>
  );
};

const getOriginalTermOption = (
  link: Link,
  group: Group | null,
  useGroupLabel: boolean,
  selectedExternalOptions: SelectedExternalOptions,
): LinkOption => {
  if (!link?.code || !link?.id) {
    return {
      label: "",
      value: "",
    };
  }

  const isSelected = !!selectedExternalOptions?.[link?.id];

  return {
    label: useGroupLabel
      ? getLinkGroupLabel(link, group, isSelected)
      : (getRegularLabel(link, isSelected) as any),
    value: link?.id,
  };
};

const getLinkOption = (
  link: Link | LinkOption,
  group: Group | null,
  useGroupLabel: boolean,
  selectedExternalOptions: SelectedExternalOptions,
): LinkOption => {
  const linkOption = link as LinkOption;

  if (linkOption?.value && linkOption?.label) return linkOption;

  return getOriginalTermOption(link as Link, group, useGroupLabel, selectedExternalOptions);
};

interface LinkSelectorProps {
  className?: string;
  isClearable?: boolean;
  label?: string;
  placeholder?: string;
  keepSelection?: boolean;
  isDisabled?: boolean;
  linksToAvoid?: string[];
  selectedExternalOptions?: SelectedExternalOptions;
  splitByGroups?: boolean;
  campusId?: string;
  getUnrestrictedLinks?: boolean;
  sendValues?: (payload: { link: string | null }) => void;
  onSelectLink?: (link?: Link) => void;
}

const savedOptions: { [key: string]: Link } = {};

const LinkSelector: React.FC<LinkSelectorProps> = props => {
  const {
    className = "",
    isClearable = true,
    label = "Liga",
    placeholder = "Seleccionar Liga",
    sendValues,
    onSelectLink,
    isDisabled = false,
    linksToAvoid = [],
    keepSelection = true,
    selectedExternalOptions = {},
    splitByGroups = false,
    campusId,
    getUnrestrictedLinks,
  } = props;
  const client = useApolloClient();
  const { scenario, origin }: IParams = useParams();
  const [valueLink, setValueLink] = useState<LinkOption | null>(null);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    setIsRefetching(true);
  }, [selectedExternalOptions]);

  const queryOptions = (searchTerm = "", page = 1) => {
    const pageSize = 20;

    const useFields = campusId || getUnrestrictedLinks !== undefined;
    const fields = {
      campusId: campusId
        ? {
            eq: campusId,
          }
        : undefined,
      getUnrestrictedLinks:
        getUnrestrictedLinks !== undefined
          ? {
              is: getUnrestrictedLinks,
            }
          : undefined,
    };

    return {
      query: LINKS_QUERY,
      variables: {
        scenarioId: scenario,
        originId: origin,
        filter: {
          fields: useFields ? fields : undefined,
          pagination: {
            page,
            size: pageSize,
            searchTerm,
          },
        },
      },
    };
  };

  const getTermOptions = async (searchTerm = "", page = 1) => {
    try {
      const query = queryOptions(searchTerm, page);
      const { data } = await client.query(query);

      const linksPage = data?.cube?.links as LinkPage;
      const links = linksPage?.items;
      const options = [];
      const filteredOptions = (links ?? []).filter(link => !linksToAvoid?.includes(link?.id));

      filteredOptions.forEach(link => {
        if (!(link.id in savedOptions)) {
          savedOptions[link.id] = link;
        }

        if (splitByGroups) {
          link?.groups?.length
            ? link?.groups?.forEach(group => {
                if (group?.campus?.id === campusId) {
                  options.push(getLinkOption(link, group, true, selectedExternalOptions));
                }
              })
            : options.push(getLinkOption(link, null, true, selectedExternalOptions));
        } else {
          options.push(getLinkOption(link, null, false, selectedExternalOptions));
        }
      });

      return {
        options: [...options],
        pageInfo: linksPage?.pageInfo,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const loadLinks = async () => {
    const hasSameTerm = prevSearchTerm === searchTerm;
    const newSearchPage = hasSameTerm && !isRefetching ? page + 1 : 1;

    setIsRefetching(false);
    setPrevSearchTerm(searchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await getTermOptions(searchTerm, newSearchPage);

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  const handleOnChange = (linkOption: IOption) => {
    setValueLink(linkOption || null);
    sendValues?.({
      link: linkOption?.value || null,
    });

    onSelectLink?.(savedOptions?.[linkOption?.value]);
  };

  return (
    <SelectPagination
      key={`link-selector-${Object.keys(selectedExternalOptions)?.length || 0}`}
      className={cx(className, css.linkSelector)}
      onInputChange={setSearchTerm}
      label={label}
      value={keepSelection ? valueLink : null}
      debounceTimeout={500}
      placeholder={placeholder}
      isClearable={isClearable}
      onChange={handleOnChange}
      loadOptions={loadLinks}
      isDisabled={isDisabled}
    />
  );
};

export default LinkSelector;
