import React, { useState } from "react";
import AdvanceCampusSelector from "../advance-campus-selector";
import AdvancedDepartmentSelector from "../advanced-department-selector";
import AdvancedModalitySelector from "../advanced-modality-selector";
import AdvancedShiftSelector from "../advanced-shift-selector";
import AdvancedProgramSelector from "../advanced-program-selector";
import AdvancedCurriculumSelector from "../advanced-curriculum-selector";
import AdvancedLevelSelector from "../advanced-level-selector";
import AdvancedSubjectSelector from "../advanced-subject-selector";
import cx from "classnames";
import css from "./advancedSelectorsWrapper.module.scss";

type Selectors =
  | "campus"
  | "department"
  | "modality"
  | "shift"
  | "program"
  | "curriculum"
  | "level"
  | "course";

type SelectorsState = {
  campus: string | null;
  department: string | null;
  modality: string | null;
  shift: string | null;
  program: string | null;
  curriculum: string | null;
  level: string | null;
  course: string | null;
};

interface AdvancedSelectorsWrapperProps {
  scenario: string;
  selectors?: Array<Selectors>;
  useCurriculumCode?: boolean;
  className?: string;
  onChangeState?: (state: SelectorsState) => void;
}

const AdvancedSelectorsWrapper = ({
  scenario,
  selectors = [
    "campus",
    "department",
    "modality",
    "shift",
    "program",
    "curriculum",
    "level",
    "course",
  ],
  className = "",
  useCurriculumCode = true,
  onChangeState,
}: AdvancedSelectorsWrapperProps) => {
  const [state, setState] = useState<SelectorsState>({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
    course: null,
  });

  const getUpdatedState = (
    selectsToRest: Array<
      "campus" | "department" | "modality" | "shift" | "program" | "curriculum" | "level" | "course"
    > = [],
  ) => {
    const { campus, department, modality, shift, program, curriculum, level, course } = state;

    return {
      campus: selectsToRest.includes("campus") ? null : campus,
      department: selectsToRest.includes("department") ? null : department,
      modality: selectsToRest.includes("modality") ? null : modality,
      shift: selectsToRest.includes("shift") ? null : shift,
      program: selectsToRest.includes("program") ? null : program,
      curriculum: selectsToRest.includes("curriculum") ? null : curriculum,
      level: selectsToRest.includes("level") ? null : level,
      course: selectsToRest.includes("course") ? null : course,
    };
  };

  const handleSelectIndependet = (
    value: string | null,
    selector: "campus" | "department" | "modality" | "shift",
  ) => {
    const updatedState = getUpdatedState(["program", "curriculum", "course"]);

    setState(prevState => {
      const newState = {
        ...prevState,
        ...updatedState,
        [selector]: value,
      };

      onChangeState?.(newState);

      return newState;
    });
  };

  const handleSelectProgram = (value: string | null) => {
    const updatedState = getUpdatedState(["curriculum", "course"]);

    setState(prevState => {
      const newState = {
        ...prevState,
        ...updatedState,
        program: value,
      };

      onChangeState?.(newState);

      return newState;
    });
  };

  const handleSelectCurriculum = (value: string | null) => {
    const updatedState = getUpdatedState(["level", "course"]);

    setState(prevState => {
      const newState = {
        ...prevState,
        ...updatedState,
        curriculum: value,
      };

      onChangeState?.(newState);

      return newState;
    });
  };

  const handleSelectLevel = (value: string | null) => {
    const updatedState = getUpdatedState(["course"]);

    setState(prevState => {
      const newState = {
        ...prevState,
        ...updatedState,
        level: value,
      };

      onChangeState?.(newState);

      return newState;
    });
  };

  const handleSelectCourse = (value: string | null) => {
    setState(prevState => {
      const newState = {
        ...prevState,
        course: value,
      };

      onChangeState?.(newState);

      return newState;
    });
  };

  if (!selectors.length) {
    return null;
  }

  return (
    <div className={cx(css.advancedSelectorsWrapper, className)}>
      {selectors.includes("campus") && (
        <AdvanceCampusSelector
          scenario={scenario}
          onChange={option => handleSelectIndependet(option?.value ?? null, "campus")}
        />
      )}

      {selectors.includes("department") && (
        <AdvancedDepartmentSelector
          scenario={scenario}
          onChange={option => handleSelectIndependet(option?.value ?? null, "department")}
        />
      )}

      {selectors.includes("modality") && (
        <AdvancedModalitySelector
          scenario={scenario}
          onChange={option => handleSelectIndependet(option?.value ?? null, "modality")}
        />
      )}

      {selectors.includes("shift") && (
        <AdvancedShiftSelector
          scenario={scenario}
          onChange={option => handleSelectIndependet(option?.value ?? null, "shift")}
        />
      )}

      {selectors.includes("program") && (
        <AdvancedProgramSelector
          key={`program-${state.campus}-${state.department}-${state.modality}-${state.shift}`}
          scenario={scenario}
          campusId={state.campus}
          departmentId={state.department}
          modalityId={state.modality}
          shiftId={state.shift}
          onChange={option => handleSelectProgram(option?.value ?? null)}
        />
      )}

      {selectors.includes("curriculum") && (
        <AdvancedCurriculumSelector
          key={`curriculum-${state.campus}-${state.department}-${state.modality}-${state.shift}-${state.program}`}
          scenario={scenario}
          campusId={state.campus}
          departmentId={state.department}
          modalityId={state.modality}
          shiftId={state.shift}
          programId={state.program}
          activeCode={useCurriculumCode}
          onChange={option => handleSelectCurriculum(option?.value ?? null)}
        />
      )}

      {selectors.includes("level") && (
        <AdvancedLevelSelector
          scenario={scenario}
          onChange={option => handleSelectLevel(option?.value ?? null)}
        />
      )}

      {selectors.includes("course") && (
        <AdvancedSubjectSelector
          key={`subject-${state.campus}-${state.department}-${state.modality}-${state.shift}-${state.program}-${state.curriculum}-${state.level}`}
          scenario={scenario}
          campusId={state.campus}
          departmentId={state.department}
          modalityId={state.modality}
          shiftId={state.shift}
          programId={state.program}
          curriculumId={state.curriculum}
          levelId={state.level}
          onChange={option => handleSelectCourse(option?.value ?? null)}
        />
      )}
    </div>
  );
};

export default AdvancedSelectorsWrapper;
