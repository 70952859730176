import React, { useContext, useState } from "react";
import { lensProp, map, pipe, prop, set } from "ramda";
import { DataGrid, Icon, Button } from "@foris/avocado-ui";
import { Column, SortInfo } from "@foris/avocado-ui/lib/types/components/DataGrid/types";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import { Context } from "../../context/GroupsManagerContext";
import cx from "classnames";
import { GroupEnrollment, OrderByDirection, PageInfo } from "@models/ISchema";
import { EnrollmentsTableColumn } from "../../models";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import { PagerProps } from "@foris/avocado-ui/lib/types/components/Pager/Pager";
import { TableFiltersReducerType } from "../../context/types";
import TableFilter from "../TableFilter/TableFilter";
import enrollmentsTableHeaderToSearchByObj from "../../utils/enrollmentsTableHeaderToSearchByObj";
import css from "./enrollmentsTableByCrn.module.scss";

const ROWS_PER_PAGE = 20;

interface IProps {
  reportUrl: string;
  enrollments: GroupEnrollment[];
  enrollmentsPageInfo: PageInfo;
  requestEnrollments: (
    page: number,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void;
}

const EnrollmentsTableByCrn = ({
  reportUrl,
  enrollments,
  enrollmentsPageInfo,
  requestEnrollments,
}: IProps) => {
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const [columnsToSearchBy, setColumnsToSearchBy] = useState<Partial<Record<any, boolean>>>({
    Campus: true,
    Carrera: true,
    Estudiante: true,
    Grupo: true,
    Estado: true,
  });

  const pagination = pipe(
    set(lensProp<PagerProps>("onChange"), (page: number) =>
      requestEnrollments(page, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy),
    ),
    set(lensProp<PagerProps>("total"), enrollmentsPageInfo?.total),
    set(lensProp<PagerProps>("page"), enrollmentsPageInfo?.page),
    set(lensProp<PagerProps>("size"), ROWS_PER_PAGE),
  )((enrollmentsPageInfo ?? {}) as PagerProps);

  const columns: DataGridProps<GroupEnrollment>["columns"] = [
    {
      header: "Estudiante",
      renderer: (enrollment: GroupEnrollment) => {
        const code = enrollment?.student?.code;
        const name = enrollment?.student?.name;
        return (
          <span>
            {code} <strong>{name}</strong>
          </span>
        );
      },
      styles: {
        width: "40%",
      },
    },
    {
      header: "Grupo",
      renderer: ({ group }: GroupEnrollment) => {
        return <span>{group?.code ?? "-"}</span>;
      },
      styles: {
        width: "20%",
        maxWidth: "120px",
      },
    },
    {
      header: "Carrera",
      renderer: ({ program }: GroupEnrollment) => {
        const { code, name } = program;
        return <span>{`${code} | ${name}`}</span>;
      },
      styles: {
        width: "20%",
      },
    },
    {
      header: "Campus",
      renderer: ({ campus }: GroupEnrollment) => {
        const { code, name } = campus;
        return <span>{`${code} | ${name}`}</span>;
      },
      styles: {
        width: "20%",
      },
    },
    {
      header: "Estado",
      renderer: (enrollment: GroupEnrollment) => {
        return <span>{enrollment?.isWaitingList ? "En espera" : "Inscrito"}</span>;
      },
      styles: {
        width: "8%",
      },
    },
    {
      header: "Fecha",
      renderer: (enrollment: GroupEnrollment) => {
        const showDate = !enrollment?.isWaitingList;
        return <span>{showDate ? enrollment?.enrollmentDate : ""}</span>;
      },
      styles: {
        width: "12%",
      },
    },
  ];

  const onHeaderColumnClick = (column: Column<GroupEnrollment>) => {
    const currentOrderBy = state?.tableFilters?.orderBy;
    if (column?.header === currentOrderBy?.header) {
      const newDirection =
        currentOrderBy?.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc;
      const newOrderBy = set(lensProp("direction"), newDirection, state?.tableFilters?.orderBy);

      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestEnrollments(enrollmentsPageInfo?.page, newOrderBy, state?.tableFilters?.searchBy);
    } else {
      const newOrderBy = enrollmentsTableHeaderToSearchByObj(
        column?.header as EnrollmentsTableColumn,
      );
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestEnrollments(enrollmentsPageInfo?.page, newOrderBy, state?.tableFilters?.searchBy);
    }
  };

  const leftHeaderComponent = () => {
    return (
      <div className={cx(css.leftHeader)}>
        <label className={css.leftHeader_title}>
          {enrollments?.length ?? 0} Estudiantes inscritos
        </label>
        <Button
          className={css.leftHeader_button}
          onClick={() => {
            mouseflow.actionTag(
              "action_groups_manager_enrollments_report",
              outerState?.base?.isMouseflowEnabled,
            );
            window.open(reportUrl, "_blank");
          }}
          variant="ghost"
        >
          <Icon icon="download" />
          <span>Descargar listado estudiantes</span>
        </Button>
      </div>
    );
  };

  return (
    <section className={cx(css.table)}>
      <DataGrid
        className={css.dataGrid}
        columns={columns}
        batch={enrollments ?? []}
        onHeaderClick={onHeaderColumnClick}
        sortInfo={{
          header: state?.tableFilters?.orderBy?.header ?? "",
          direction:
            (state?.tableFilters?.orderBy?.direction?.toLowerCase() as SortInfo["direction"]) ??
            "desc",
        }}
        header={{
          left: leftHeaderComponent(),
          right: (
            <TableFilter
              hideColumnsToHideConfig={true}
              selectableColumns={map(prop("header"), columns) as EnrollmentsTableColumn[]}
              setColumnsToSearchBy={setColumnsToSearchBy}
              columnsToSearchBy={columnsToSearchBy}
              headerToOrderByObjCallback={enrollmentsTableHeaderToSearchByObj}
              request={(
                newOrderBy: TableFiltersReducerType["orderBy"],
                newSearchBy: TableFiltersReducerType["searchBy"],
              ) => requestEnrollments(1, newOrderBy, newSearchBy)}
            />
          ),
        }}
        pagination={pagination}
      />
    </section>
  );
};

export default EnrollmentsTableByCrn;
