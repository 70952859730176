import React, { useState, useCallback, useEffect } from "react";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-suite";
import { IParams } from "@models/IParams";
import { SelectableOption } from "../../models";
import { shortMegaSearch } from "@dimensions/package/graphql/packageMegaSearch.query";
import { Package } from "@models/ISchema";

const REQUEST_SIZE = 20;

interface PackageSelectorProps {
  initialValue?: SelectableOption<Package> | null;
  onSelectPackage?: (payload: Package) => void;
}

const PackageSelector = (props: PackageSelectorProps) => {
  const { onSelectPackage, initialValue } = props;
  const client = useApolloClient();
  const noSelectedOption = { id: "*", value: "*", label: "Buscar", self: null };
  const [selectedPackage, setSelectedPackage] = useState<SelectableOption<Package>>(
    initialValue || noSelectedOption,
  );
  const [page, setPage] = useState(0);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const { origin, scenario }: IParams = useParams();

  useEffect(() => {
    onSelectPackage?.(selectedPackage?.self);
  }, [selectedPackage]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      try {
        const variables = {
          query: shortMegaSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filter: {
              pagination: {
                searchTerm,
                page: page,
                size: REQUEST_SIZE,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const pageInfo = data?.cube?.packages?.pageInfo;
        const options = (data?.cube?.packages?.items ?? [])?.map((item: Package) => ({
          id: item?.id,
          value: item?.id,
          label: item?.code,
          self: item,
        }));

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  return (
    <SelectPagination
      label="Paquete"
      placeholder="Busca por código o nombre"
      noOptionsMessage={() => "Sin opciones"}
      isDisabled={false}
      value={selectedPackage}
      onChange={setSelectedPackage}
      loadOptions={loadOptions}
    />
  );
};

export default PackageSelector;
