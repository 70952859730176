import { useState } from "react";
import {
  lensPath,
  view,
  set,
  pipe,
  lensProp,
  map,
  when,
  always,
  curry,
  values,
  omit,
  isEmpty,
} from "ramda";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import {
  PageInfo,
  GroupsManagerSearchFilterFieldsInput,
  AcademicUnit,
  Campus,
  Subject,
  IntFilterInput,
} from "@models/ISchema";
import { FiltersReducerType, TableFiltersReducerType } from "../context/types";
import { groupsSearch } from "../graphql/groups.query";
import { groupAdapter } from "../adapters/group";
import { AdaptedGroup } from "../models";

interface IPagination {
  page: number;
  size: number;
}

type UseGetGroupsValue = [
  { response: AdaptedGroup[]; pageInfo: PageInfo; isLoading: boolean },
  (
    filters: FiltersReducerType,
    pagination: IPagination,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void,
];

export const useGetGroups = (): UseGetGroupsValue => {
  const { scenario, origin }: IParams = useParams();
  const client = useApolloClient();
  const [response, setResponse] = useState<AdaptedGroup[]>(null);
  const [pageInfo, setPageInfo] = useState<PageInfo>(null);
  const [isLoading, setIsLoading] = useState(false);

  const viewItems = view(lensPath(["cube", "groupsManagerSearch", "items"]));
  const viewPageInfo = view(lensPath(["cube", "groupsManagerSearch", "pageInfo"]));

  const addCrnFilter = curry(
    (
      filters: FiltersReducerType,
      fields: GroupsManagerSearchFilterFieldsInput,
    ): GroupsManagerSearchFilterFieldsInput =>
      set(
        lensPath<GroupsManagerSearchFilterFieldsInput>(["clientCode", "eq"]),
        filters?.selectedCrn,
        fields,
      ),
  );

  const addMainFilters = curry(
    (
      filters: FiltersReducerType,
      fields: GroupsManagerSearchFilterFieldsInput,
    ): GroupsManagerSearchFilterFieldsInput => {
      const eq = (field: Campus | AcademicUnit | Subject) => {
        // "*" means `all options`, so nothing should be filtered
        if (!field || field?.id === "*") return undefined;
        return { eq: field?.id };
      };

      const courseTypesToFilterBy = pipe(values, map(eq))(filters?.courseTypesById);

      return pipe(
        set(lensProp<GroupsManagerSearchFilterFieldsInput>("campus"), eq(filters?.campus)),
        set(lensProp<GroupsManagerSearchFilterFieldsInput>("department"), eq(filters?.department)),
        set(lensProp<GroupsManagerSearchFilterFieldsInput>("school"), eq(filters?.school)),
        set(lensProp<GroupsManagerSearchFilterFieldsInput>("subject"), eq(filters?.subject)),
        when(
          always(!isEmpty(courseTypesToFilterBy)),
          set(
            lensProp<GroupsManagerSearchFilterFieldsInput>("courseTypes"),
            (courseTypesToFilterBy as unknown) as IntFilterInput[],
          ),
        ),
      )(fields);
    },
  );

  const addAdvancedFilters = curry(
    (
      filters: FiltersReducerType,
      fields: GroupsManagerSearchFilterFieldsInput,
    ): GroupsManagerSearchFilterFieldsInput => {
      return pipe(
        when(
          always(filters?.state?.active !== filters?.state?.inactive),
          set(lensPath(["isActive", "is"]), Boolean(filters?.state?.active)),
        ),
        when(
          always(filters?.visibility?.visible !== filters?.visibility?.invisible),
          set(lensPath(["visibleForEnrollment", "is"]), Boolean(filters?.visibility?.visible)),
        ),
        when(
          always(filters?.availability?.available !== filters?.availability?.unavailable),
          set(lensPath(["hasVacancies", "is"]), Boolean(filters?.availability?.available)),
        ),
        when(
          always(filters?.enrollments?.with !== filters?.enrollments?.without),
          set(lensPath(["hasEnrollments", "is"]), Boolean(filters?.enrollments?.with)),
        ),
        when(always(filters?.owned), set(lensPath(["isOwner", "is"]), true)),
      )(fields);
    },
  );

  const variables = (
    filters: FiltersReducerType,
    pagination: IPagination,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    const filtersToAdd = Boolean(filters?.selectedCrn)
      ? addCrnFilter(filters)
      : pipe(addMainFilters(filters), addAdvancedFilters(filters));
    const fields = filtersToAdd({});

    const variables = {
      scenarioId: scenario,
      originId: origin,
      filter: {
        fields: {
          ...fields,
          isGroupedByClientCode: !filters?.selectedPackage
            ? {
                is: true,
              }
            : undefined,
          package: filters?.selectedPackage
            ? {
                eq: filters?.selectedPackage,
              }
            : undefined,
        },
        pagination,
        orderBy: isEmpty(orderBy) || orderBy?.field == null ? undefined : omit(["header"], orderBy),
        searchBy: isEmpty(searchBy) || searchBy?.text == null ? undefined : searchBy,
      },
    };

    return variables;
  };

  const getGroups = async (
    filters: FiltersReducerType,
    pagination: IPagination,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    try {
      setIsLoading(true);

      const { data, errors } = await client.query({
        query: groupsSearch,
        variables: variables(filters, pagination, orderBy, searchBy),
        fetchPolicy: "no-cache",
      });

      if (errors) {
        console.error("[GroupsSearch error]", errors);
        return null;
      }

      setResponse(map(groupAdapter, viewItems(data)));
      setPageInfo(viewPageInfo(data));
    } catch (error) {
      console.error("[GroupsSearch error]: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ response, pageInfo, isLoading }, getGroups];
};
