import React, { useEffect, useState, useContext, useMemo } from "react";
import * as R from "ramda";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { Button, CardState, SelectPagination } from "@foris/avocado-ui";
import { Package } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Context } from "../context/PackagesContext";
import { ContextApp } from "@config/Context/contextApp";
import { CustomPackage } from "../context/types";
import { Types as DataTypes } from "../context/data.reducer";
import { Types as PageTypes } from "../context/page.reducer";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import PackagesTable from "../components/PackagesTable";
import { key } from "../utils";
import { usePaginatedPackages } from "../hooks/usePaginatedPackages";
import css from "./packages.module.scss";
import cx from "classnames";

interface ISelectedPackage {
  id: string;
  code: string;
  label: string;
  value: string;
  self: CustomPackage;
}

export type linkPackagesClash = { [key: string]: "error" | "warning" };

interface Props {
  onSubmit: (dryRun: boolean, skipValidations: boolean) => void;
  onPackageLinkAssignmentSubmit: (dryRun: boolean, skipValidations: boolean) => void;
  linkPackages: CustomPackage[];
  linkPackagesErrors: { [key: string]: boolean };
  linkPackagesClash?: linkPackagesClash;
}

interface ErrorMessageProps {
  errors: { [key: string]: Package };
  origin: string;
  scenario: string;
  workspace: string;
}

const getPackageOption = (data: Package) => {
  return {
    label: (
      <div className={css.packageLabel}>
        <p className={css.packageLabel_mainLabel}>
          PE: <b>{data?.population?.term?.code}</b> | SE: <b>{data?.population?.campus?.code}</b> |
          CA: <b>{data?.population?.program?.code}</b> | CU: <b>{data?.population?.curriculum}</b> |
          NI: <b>{data?.population?.level}</b> | PQ: <b>{data?.index}</b>
        </p>

        <p className={css.packageLabel_secondaryLabel}>
          ES: <b>{data?.population?.program?.department?.code}</b> | MO:{" "}
          <b>{data?.population?.modality?.code}</b> | JO: <b>{data?.population?.shift?.code}</b>
        </p>
      </div>
    ) as any,
    value: data?.id,
    self: data,
  };
};

const getPackagesKeyObj = (packages: Package[] = []) => {
  const packagesObj = {};

  packages.forEach(item => {
    packagesObj[item.id] = item;
  });

  return packagesObj;
};

const getPackagesErrorsObj = (
  errors: { [key: string]: boolean } | linkPackagesClash,
  packagesObj: { [key: string]: Package },
) => {
  const errorsObj = {};

  Object.keys(errors).forEach(err => {
    const [code] = err.split("-");

    if (code in packagesObj) {
      errorsObj[code] = packagesObj[code];
    }
  });

  return errorsObj;
};

const getErrorLinks = ({ origin, scenario, workspace, errors }: ErrorMessageProps) => {
  const baseRoute = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;

  const keys = Object.keys(errors);
  return keys.map((key, index) => {
    const error = errors[key];

    return (
      <>
        <Link
          key={error?.code + index}
          to={`${baseRoute}/${error?.id}`}
          className={css.packageLink}
        >
          [{error?.code}]
        </Link>
        {index + 1 < keys.length ? ", " : ""}
      </>
    );
  });
};

const getErrorMessage = (payload: ErrorMessageProps) => {
  const links = getErrorLinks(payload) || [];
  const singular = `El paquete agregado`;
  const plural = `Los paquetes agregados`;

  const isSingular = links.length <= 1;

  return (
    <p>
      {isSingular ? singular : plural} {links} ya contiene{!isSingular && "n"} una Liga de la misma
      llave de asignatura. No es posible generar la vinculación.
    </p>
  );
};

const getClashMessage = (payload: ErrorMessageProps, isError = false) => {
  const links = getErrorLinks(payload) || [];

  return (
    <p>
      Al agregar esta liga al Paquete {links} se generan choques horarios dentro del paquete.{" "}
      {isError ? "No tienes permisos para guardar cambios que generan Choques de Paquete" : ""}
    </p>
  );
};

const PackagesEdition = (props: Props) => {
  const {
    onSubmit,
    onPackageLinkAssignmentSubmit,
    linkPackages,
    linkPackagesErrors,
    linkPackagesClash,
  } = props;
  const { state, dispatch } = useContext(Context);
  const { user } = useContext(ContextApp);
  const { origin, scenario, workspace }: IParams = useParams();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [inputText, setInputText] = useState("");
  const [doSubmission, setDoSubmission] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<ISelectedPackage>(null);
  const history = useHistory();

  const hasEditPermissions = !!user?.abilities?.can_edit_packages;

  const hasClashError = useMemo(
    () => Object.values(linkPackagesClash).some(val => val === "error"),
    [linkPackagesClash],
  );
  const allNewPackagesAreSavable = (): boolean => R.isEmpty(linkPackagesErrors) && !hasClashError;

  const canSubmit = (): boolean => {
    const { creations, deletions, assignments } = state?.data;
    const [someCreation, someDeletion, someAssignment] = R.ap(
      [R.pipe(R.isEmpty, R.not)],
      [creations, deletions, assignments],
    );
    return (
      !R.any(R.equals(true), [someDeletion, someCreation, someAssignment]) ||
      !allNewPackagesAreSavable()
    );
  };

  const addAssignment = () => {
    const payload = { package: selectedPackage?.self, linkId: state?.data?.link?.id };
    dispatch({ type: DataTypes.AddAssignment, payload });
    setSelectedPackage(null);
    setDoSubmission(true);
  };

  const handleCancel = () => {
    if (canSubmit()) {
      history.replace(
        `/editor/vacancies/${workspace}/${scenario}/${origin}/${state?.data?.link?.bundle?.id}`,
      );
      return;
    }

    dispatch({ type: DataTypes.CleanCreations });
    dispatch({ type: DataTypes.CleanDeletions });
    dispatch({ type: DataTypes.CleanAssignments });
  };

  const onDelete = (customPackage: CustomPackage) => {
    const linkId = state?.data?.link?.id;
    if (customPackage?.isNew) {
      const { creations, assignments } = state?.data;
      const packageLinkKey = key(customPackage?.id, linkId);
      const populationLinkKey = key(customPackage?.population?.code, linkId);

      if (customPackage?.isReplicated !== null && !!customPackage?.sourcePopulationCode) {
        const assignmentsClone = { ...assignments };

        Object.keys(assignmentsClone).forEach(assignmentKey => {
          const pkg = assignmentsClone?.[assignmentKey];

          if (
            pkg?.package?.isReplicated !== null &&
            [pkg?.package?.code, pkg?.package?.sourcePopulationCode].includes(
              customPackage?.sourcePopulationCode,
            )
          ) {
            delete assignmentsClone?.[assignmentKey];
          }
        });

        dispatch({ type: DataTypes.DeleteAssignments, payload: assignmentsClone });
      } else if (R.has(packageLinkKey, assignments)) {
        const payload = { package: customPackage, linkId };
        dispatch({ type: DataTypes.DeleteAssignment, payload });
      } else if (R.has(populationLinkKey, creations)) {
        const payload = { population: customPackage?.population, linkId };
        dispatch({ type: DataTypes.DeleteCreation, payload });
      } else {
        // unreachable (allegedly...)
        console.error("No package-link or population-link to delete...");
      }
    } else {
      dispatch({ type: DataTypes.AddDeletion, payload: { package: customPackage, linkId } });
    }
  };

  useEffect(() => {
    if (R.not(R.isEmpty(state?.data?.assignments)) && doSubmission) {
      setDoSubmission(false);
      onPackageLinkAssignmentSubmit(true, true);
    }
  }, [state?.data?.assignments]);

  /**
   * Reject the packages that are already related to the link or are currently been created
   */
  const selectablePackages = (packgs: Package[]): Package[] => {
    const createdPackagesIds = R.pipe(
      R.values,
      R.map(R.prop<Package>("package")),
      R.reduce((acc, { id }: Package) => R.assoc(id, true, acc), {}),
    )(state?.data?.assignments);
    const currentPackagesIds = R.pipe(
      R.map(R.propOr<string>("", "id")),
      R.reduce((acc, id: string) => R.assoc(id, true, acc), {}),
    )(state?.data?.link?.packages ?? []);
    const alreadyDisplayed = (id: string) =>
      R.or(R.has(id, createdPackagesIds), R.has(id, currentPackagesIds));

    return R.reject(R.pipe(R.prop<"id", string>("id"), alreadyDisplayed), packgs);
  };

  const packagesObj = useMemo(() => getPackagesKeyObj(linkPackages), [linkPackages]);
  const packagesErrorsObj = useMemo(() => getPackagesErrorsObj(linkPackagesErrors, packagesObj), [
    linkPackagesErrors,
    packagesObj,
  ]);
  const errorMessage = useMemo(
    () =>
      getErrorMessage({
        errors: packagesErrorsObj,
        origin,
        scenario,
        workspace,
      }),
    [packagesErrorsObj],
  );
  const hasErrorMessages = !!Object.keys(packagesErrorsObj).length;

  const packagesClashObj = useMemo(() => getPackagesErrorsObj(linkPackagesClash, packagesObj), [
    linkPackagesClash,
    packagesObj,
  ]);
  const hasPackagesClash = !!Object.keys(packagesClashObj).length;
  const clashMessage = useMemo(
    () =>
      getClashMessage(
        {
          errors: packagesClashObj,
          origin,
          scenario,
          workspace,
        },
        hasClashError,
      ),
    [packagesClashObj],
  );

  const packagesFilterMethod = (packages: Package[] = []): RegularOption[] => {
    const packagesFiltered = selectablePackages([...packages]);
    const options = packagesFiltered.map(getPackageOption);

    return options;
  };

  const linkCampus = state?.data?.link?.course?.curriculum?.program?.campus;
  const isGlobalCampus = !!linkCampus?.isGlobal;

  const { loadPackages, isLoading: isLoadingPackagesOptions } = usePaginatedPackages({
    scenarioId: scenario,
    originId: origin,
    filterId: params?.advance,
    searchTerm: inputText,
    campusId: isGlobalCampus ? undefined : linkCampus?.id,
    fields: {
      isPublished: {
        is: false,
      },
    },
    packagesFilterMethod,
  });

  return (
    <>
      <section className={css.main}>
        <h2>{hasEditPermissions && "Editar "}Paquetes</h2>

        {/* Top Selector */}
        {hasEditPermissions && (
          <div className={css.selector}>
            <SelectPagination
              className={css.selector__input}
              label={"Agregar paquete existente"}
              placeholder={"Agregar paquete existente"}
              value={selectedPackage}
              onInputChange={setInputText}
              onChange={setSelectedPackage}
              loadOptions={loadPackages}
              isLoading={isLoadingPackagesOptions}
              debounceTimeout={1000}
            />
            <Button
              typeButton={"white"}
              disabled={!Boolean(selectedPackage)}
              className={cx(
                css.selector__button,
                !Boolean(selectedPackage) ? css.selector__button_disabled : "",
              )}
              outline={true}
              onClick={addAssignment}
            >
              Agregar paquete
            </Button>
            <Button
              typeButton={"white"}
              className={css.selector__button}
              outline={true}
              onClick={() => dispatch({ type: PageTypes.SetActivePage, payload: "CREATION" })}
            >
              Crear nuevo paquete
            </Button>
          </div>
        )}

        {/* Packages Table */}
        <PackagesTable
          linkPackages={linkPackages ?? []}
          onDelete={onDelete}
          linkId={state?.data?.link?.id}
          deletions={state?.data?.deletions}
          linkPackagesErrors={linkPackagesErrors}
          hasEditPermissions={hasEditPermissions}
        />

        {hasErrorMessages && (
          <CardState
            className={css.errorCard}
            typeCard="error"
            title="No se puede vincular Paquete a Liga"
          >
            {errorMessage}
          </CardState>
        )}

        {hasPackagesClash && (
          <CardState
            className={css.errorCard}
            typeCard={hasClashError ? "error" : "warning"}
            title={
              hasClashError
                ? "No puedes guardar cambios que generan choques de paquete."
                : "Choque de horario en el paquete"
            }
          >
            {clashMessage}
          </CardState>
        )}

        {/* Bottom buttons */}
        <section className={css.actions}>
          <Button
            className={cx(css.selector__button)}
            typeButton={"white"}
            outline={true}
            onClick={handleCancel}
          >
            Cancelar
          </Button>

          {hasEditPermissions && (
            <Button
              disabled={canSubmit()}
              className={cx(css.selector__button, canSubmit() ? css.selector__button_disabled : "")}
              onClick={() => onSubmit(false, true)}
            >
              Guardar
            </Button>
          )}
        </section>
      </section>
    </>
  );
};

export default PackagesEdition;
