import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Loading, Pill, Link } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, Group, BaseQuery } from "@models/ISchema";
import { IParams } from "../../../../models/IParams";
import { groupSection } from "../graphql/groupSection.query";
import Tags from "./Tags/Tags";
import Reference from "./Reference/Reference";
import { ContextApp } from "@config/Context/contextApp";
import { GroupContext } from "../context/GroupContext";
import { Types } from "../context/groupInfo.reducer";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";
import css from "../../../../common/components/Header/header.module.scss";
import groupCss from "./group.module.scss";

const attributeTag = "itesm.mx/attribute";

const GroupCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const context = useContext(ContextApp);
  const { state, dispatch } = useContext(GroupContext);
  const [calendarData, setCalendar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.group);
  const { t } = useTranslation();

  const variables = {
    query: groupSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "GROUP",
    },
  };

  const headerInfo = (groupInfo: Group, customLabel?: any) => {
    const details = [];
    const uniqueRow = [];
    const courseTags = JSON.parse(groupInfo?.course?.tags || "{}");

    if (groupInfo.type) {
      details.push({ title: "Tipo de grupo", content: groupInfo?.type || "" });
    }

    if (groupInfo.totalBlocks) {
      details.push({
        title: "Bloques totales grupo",
        content: groupInfo?.totalBlocks?.toString() || "",
      });
    }

    if (groupInfo.totalBlocksWithInstructors) {
      details.push({
        title: "Bloques con docentes totales grupo",
        content: groupInfo?.totalBlocksWithInstructors?.toString() || "",
      });
    }

    const groupCampus = R.view(R.lensPath(["course", "curriculum", "program", "campus", "name"]));
    details.push({ title: "Sede", content: groupCampus(groupInfo) });

    if (attributeTag in courseTags) {
      details.push({
        title: "Atributos",
        isIndependent: true,
        content: courseTags?.[attributeTag] || "-",
      });
    }

    if (customLabel) {
      customLabel.forEach((item: any) => {
        const value = groupInfo.customFields.find(customData => customData.code === item.code);
        if (value) {
          uniqueRow.push({
            title: item.label,
            content: value?.value?.replace(",", ", "),
          });
        }
      });
    }

    const route = `/scheduler/editor/group/edit-labels/${workspace}/${scenario}/${origin}/${id}`;
    const topTitle =
      groupInfo?.code && groupInfo?.clientCode ? (
        <h2 className={css.infoItem_topTitle}>
          {groupInfo?.code?.toString()}
          <span className={css.lower}>{` | Cod. Cliente: `}</span>
          {groupInfo?.clientCode}
        </h2>
      ) : (
        (groupInfo?.code && !groupInfo?.clientCode && groupInfo?.code?.toString()) || ""
      );

    const title = (
      <Heading darkMode type="h1" className={css.infoItem_title}>
        {groupInfo?.name || ""}
        {groupInfo?.visibleForEnrollment ? (
          <Icon name="open-eye" size="lg" color="gray-10" className={css.icon} />
        ) : (
          <Icon name="closed-eye" color="gray-10" size="lg" className={css.icon} />
        )}
      </Heading>
    );

    const subTitle = () => {
      const capacity = groupInfo?.capacity || "";
      const enrollmentsCount = groupInfo?.enrollmentStats?.enrollmentsCount
        ? `| ${groupInfo?.enrollmentStats?.enrollmentsCount}`
        : "";
      const waitingListsCount =
        groupInfo?.enrollmentStats?.waitingListsCount &&
        groupInfo?.enrollmentStats?.waitingListsCount !== 0
          ? ` (+${groupInfo?.enrollmentStats?.waitingListsCount})`
          : "";
      const idBundle = groupInfo?.links[0].bundle.id;

      const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${idBundle}`;
      return (
        <div className={cx(css.infoItem_subTitle, css.infoItem_subTitleWrapper)}>
          <Pill color="gray-70" textColor="gray-10">
            {t("dimension.group.pill-section.period")}: {groupInfo?.term?.code}
          </Pill>
          <Pill color="gray-70" textColor="gray-10">
            {t("dimension.group.pill-section.vacancies")}:{" "}
            {`${capacity} ${enrollmentsCount} ${waitingListsCount}`}
          </Pill>
          {idBundle && (
            <Link size="sm" iconRight="external-link" href={routeBundle} className={cx(css.link)}>
              {t("dimension.group.link")}: {groupInfo?.links[0].bundle.code}
            </Link>
          )}
        </div>
      );
    };

    uniqueRow.length > 0 && details.push(uniqueRow);

    const labels = [];
    const systemLabels = [];

    groupInfo?.labels?.forEach(label => {
      if (label?.category?.labelSource?.code !== "USER-DEFINED") {
        systemLabels.push(label);
      } else {
        labels.push(label);
      }
    });

    return {
      title: title,
      topTitle: topTitle,
      subTitle: subTitle(),
      moreDetails: details || labels,
      children: (
        <>
          <div
            className={cx(
              groupCss.labelsWrapper,
              context?.permits?.groupText && groupCss.labelsWrapper__bordered,
            )}
          >
            {systemLabels?.length > 0 && (
              <Tags
                sectionTitle={t("header.tag-automatically.title")}
                labels={systemLabels}
                addMargin
                canEdit={false}
              />
            )}

            <Tags
              sectionTitle={t("header.tag-manual.title")}
              route={route}
              labels={labels}
              addMargin={details.length > 0}
            />
          </div>
          {context?.permits?.groupText && (
            <div className={groupCss.labelsWrapper}>
              <Reference />
            </div>
          )}
        </>
      ),
    };
  };

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data?.cube;
        const baseQuery: BaseQuery = data?.base;
        const customFieldsQuery = baseQuery?.customFields;
        const info: any = cubeQuery.editorView.info;
        const groupInfo: Group = info.group;
        if (groupInfo) {
          dispatch({
            type: Types.SetGroupInfo,
            payload: groupInfo,
          });
          dispatch({
            type: Types.SetGroupCustomFields,
            payload: customFieldsQuery,
          });
          const sections: ISectionData = functionsData.serializeSections(data);
          setCalendar(sections);
        } else {
          setError(true);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  const header = state?.info?.info
    ? headerInfo(state?.info?.info, state?.info?.customFields)
    : null;

  return (
    <>
      {header && (
        <Header
          dimension={dimension.label}
          breadcrumbItems={[
            {
              path: "/",
              title: t("dimension.group.breadcrumb.previous"),
            },
            {
              title: t("dimension.group.breadcrumb.current"),
            },
          ]}
          {...header}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension="Grupo" />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          defaultTerm={state?.info?.info?.term}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </>
  );
};

export default GroupCalendar;
