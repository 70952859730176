import React, { useState, useContext, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Text } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-ui";
import { Breadcrumbs } from "@foris/avocado-suite";
import cx from "classnames";
import Details from "./Details/Details";
import { IInfoHeader } from "./IHeaderModule";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import SectionMenu from "./SectionMenu";
import css from "./header.module.scss";

const Header: React.FC<IInfoHeader> = (props: IInfoHeader) => {
  const {
    className = "",
    titleClassName = "",
    type,
    topTitle,
    title,
    subTitle,
    canEditCourse,
    children,
    moreDetails,
    setHeight,
    dimension,
    renderMenu,
    hasSessions,
    onShowError,
    breadcrumbItems,
  } = props;
  const [openInfo, setOpenInfo] = useState(false);

  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const { t } = useTranslation();

  const userCanEdit =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_edit_links_and_sections &&
    type === "Sections" &&
    canEditCourse;

  const isStringTopTitle = typeof topTitle === "string";
  const isStringTitle = typeof title === "string";
  const headerDimension = useRef(null);

  useEffect(() => {
    if (headerDimension !== null && headerDimension.current && setHeight) {
      setHeight(headerDimension.current.offsetHeight);
    }
  }, []);

  return (
    <header
      className={cx(
        css.cntHeader,
        moreDetails && moreDetails.length > 0 && css.cntHeader__moreInfo,
        "container-row row--between",
        className,
      )}
      ref={headerDimension}
    >
      {breadcrumbItems?.length > 0 && (
        <div className={css.cntHeader_breadcrumbContent}>
          <Breadcrumbs darkMode items={breadcrumbItems} />
        </div>
      )}

      <div className={cx(css.cntHeader_content, openInfo && css.cntHeader_content__divided)}>
        {dimension ? (
          <div className={css.dimensionWrapper}>
            <Text className={css.dimensionWrapper_title}>{t("header.dimension.title")}</Text>
            <Heading className={css.dimensionWrapper_name} type="h1">
              {dimension}
            </Heading>
          </div>
        ) : null}

        <section className={css.infoItem}>
          {isStringTopTitle ? (
            <Text darkMode className={css.infoItem_topTitle}>
              {topTitle}
            </Text>
          ) : (
            <>{topTitle}</>
          )}
          {isStringTitle ? (
            <Heading type="h1" darkMode className={cx(css.infoItem_title, titleClassName)}>
              {title}
            </Heading>
          ) : (
            <>{title}</>
          )}
          {subTitle && <div className={css.infoItem_container}>{subTitle}</div>}
        </section>

        {userCanEdit || renderMenu ? (
          <div className={css.menuWrapper}>
            {renderMenu ? renderMenu() : null}

            {userCanEdit ? (
              <SectionMenu
                hasSessions={hasSessions}
                openEdit={() => props.callbackOpenEdit(true)}
                openDisabledSession={() => props.callbackOpenDisabledSession(true)}
                openChangeComponent={() => props.callbackOpenChangeComponent(true)}
                onDisabledSessionError={() => onShowError?.()}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={openInfo ? css.detailsWrapper : ""}>
        <Details data={moreDetails} active={openInfo}>
          {children}
        </Details>
      </div>

      {(moreDetails && moreDetails.length > 0) || children ? (
        <div className={cx(css.linkTo, "container-row row--center")}>
          <button
            onClick={() => {
              !openInfo &&
                mouseflow.actionTag("action_open_header", state?.base?.isMouseflowEnabled);
              setOpenInfo(!openInfo);
            }}
            className={cx(css.linkTo_btn, openInfo && css.linkTo_btn__active)}
          >
            <Icon icon="chevron-down" size={16} className={css.icon} />
            <Heading type="h4" className={css.title}>
              {openInfo
                ? t("header.information-additional.less")
                : t("header.information-additional.more")}
              {t("header.information-additional.title")}
            </Heading>
          </button>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
