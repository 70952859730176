import React, { useMemo } from "react";
import { Text } from "@foris/avocado-suite";
import { IDetails } from "../IHeaderModule";
import cx from "classnames";
import css from "./DetailsItems.module.scss";

interface DetailsItemsProps {
  items: IDetails[];
}

const DetailsItems: React.FC<DetailsItemsProps> = ({ items }) => {
  const filteredItems = useMemo(() => {
    const groupedItems = [];
    const independentItems = [];

    for (const item of items) {
      if (item.isIndependent) {
        independentItems.push(item);
      } else {
        groupedItems.push(item);
      }
    }

    return {
      items: groupedItems,
      independentItems,
    };
  }, [items]);

  return (
    <>
      {filteredItems?.items?.length > 0 && (
        <div className={css.items}>
          {filteredItems?.items.map((item, index) => (
            <div className={css.items_details} key={index}>
              <Text type="sm" className={css.detailsTitle}>
                {item.title}
              </Text>
              <Text className={css.detailsContent}>{item.content}</Text>
            </div>
          ))}
        </div>
      )}

      {filteredItems?.independentItems?.length > 0 && (
        <div className={cx(css.items, css.items__independent)}>
          {filteredItems?.independentItems?.map((item, index) => (
            <div className={css.items_details} key={index}>
              <Text type="sm" className={css.detailsTitle}>
                {item.title}
              </Text>
              <Text className={css.detailsContent}>{item.content}</Text>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DetailsItems;
