import queryString from "query-string";
import { DimensionNameEs } from "../../../models/IDimension";

export const variablesSearch = (
  scenario: string,
  origin: string,
  params: queryString.ParsedQuery<string>,
  page: number,
  useFilterPagination?: boolean,
) => {
  const variables = {
    scenarioId: scenario,
    originId: origin,
    filterId: params?.advance,
    filter: {
      page: page,
      size: 20,
      searchTerm: params?.search,
    },
    pagination: {
      page: page,
      size: 20,
    },
  };
  if (params?.search) {
    delete variables.filterId;
    delete variables.pagination;
  }
  if (params?.advance) {
    delete variables.filter;
    delete variables.originId;
  }

  if (useFilterPagination) {
    delete variables.pagination;

    variables.filter = {
      pagination: {
        page: page,
        size: 20,
        searchTerm: params?.search,
      },
    } as any;
  }

  return variables;
};

export const generateTitle = (total: number, search: string, dimension: DimensionNameEs) =>
  search
    ? `${total} Resultados en ${dimension} para "${search}"`
    : `${total} Resultados en ${dimension}`;

export const getAdvancedSelectorsFilters = ({
  campusId,
  buildingId,
  classroomTypeId,
}: {
  campusId?: string;
  buildingId?: string;
  classroomTypeId?: string;
}) => {
  const filterArray = [];

  if (campusId) filterArray.push({ building: { campusId: { eq: campusId } } });
  if (buildingId) filterArray.push({ buildingId: { eq: buildingId } });
  if (classroomTypeId) filterArray.push({ classroomTypeId: { eq: classroomTypeId } });

  return filterArray;
};
