import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { EmptyResult } from "../../../shared/modals";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { groupSearchMutation } from "./../graphql/advanceSearch.queries";
import { getCurriculumAndProgramFilters } from "@modules/advance-search/utils/filterVariables";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import AdvancedLabelsSelectors from "@modules/advance-search/components/advanced-labels-selectors";
import css from "../../../advance-search/advance-search.module.scss";

const AdvanceSearchGroup: React.FC<any> = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(groupSearchMutation, {});
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLabelCategory, setSelectedLabelCategory] = useState<string | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
    course: null,
  });

  const handleOnChangeLabels = (payload: { categoryId?: string | null; labelsIds: string[] }) => {
    setSelectedLabelCategory(payload.categoryId);
    setSelectedLabels(payload.labelsIds);
  };

  const labelsFilters = useMemo(() => {
    if (!selectedLabels?.length) {
      return selectedLabelCategory
        ? [
            {
              label: {
                labelCategoryId: { eq: selectedLabelCategory },
              },
            },
          ]
        : [];
    }

    return selectedLabels?.map(labelId => ({
      label: {
        id: {
          eq: labelId,
        },
      },
    }));
  }, [selectedLabelCategory, selectedLabels]);

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => value !== null) || labelsFilters.length > 0;
  }, [selectorsState, labelsFilters]);

  const createObjectMutation = () => {
    const filterArray = [];
    const curriculumFilters = getCurriculumAndProgramFilters({
      curriculumCode: selectorsState.curriculum,
      programCode: selectorsState.program,
    });

    (selectorsState?.curriculum || selectorsState?.program) && filterArray.push(curriculumFilters);
    selectorsState.campus && filterArray.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && filterArray.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      filterArray.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && filterArray.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.course && filterArray.push({ courseId: { eq: selectorsState.course } });
    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    filterArray.push(...labelsFilters);

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter:
        filterArray?.length > 0
          ? {
              fields: {
                and: filterArray,
              },
            }
          : undefined,
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createGroupFilter;
      if (dataItems.groups.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/group/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <AdvancedSelectorsWrapper scenario={scenario} onChangeState={setSelectorsState} />

      <AdvancedLabelsSelectors
        scenario={scenario}
        contentType="group"
        onChange={handleOnChangeLabels}
      />

      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <Button
          className={css.actionButton}
          onClick={() => callUpdateHook()}
          disabled={!hasSomeFilter}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchGroup;
