export const getCurriculumAndProgramFilters = ({
  curriculumCode,
  programCode,
}: {
  curriculumCode?: string;
  programCode?: string;
}) => {
  const filters =
    curriculumCode || programCode
      ? {
          curriculum: {
            code: curriculumCode ? { eq: curriculumCode } : undefined,
            program: programCode ? { code: { eq: programCode } } : undefined,
          },
        }
      : null;

  return filters;
};
