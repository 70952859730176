import React from "react";
import { IconTypes } from "@foris/avocado-icons";
import { Button } from "@foris/avocado-suite";
import cx from "classnames";
import styles from "./floatButton.module.scss";

interface FloatButtonProps {
  children?: React.ReactNode;
  iconRight?: IconTypes;
  iconLeft?: IconTypes;
  isOutlined?: boolean;
  isVisible?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FloatButton = ({
  children,
  iconLeft,
  iconRight,
  isOutlined,
  isVisible,
  onClick,
}: FloatButtonProps) => {
  return (
    <Button
      className={cx(
        styles.floatButton,
        isOutlined && styles.floatButton__outlined,
        isVisible ? styles.floatButton__visible : styles.floatButton__hidden,
      )}
      variant={isOutlined ? "ghost" : "primary"}
      rightIcon={iconRight}
      leftIcon={iconLeft}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default FloatButton;
