import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useParams, useHistory } from "react-router-dom";
import { Loading, Button } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import { LinkFilterInput } from "@models/ISchema";
import { EmptyResult } from "../../../../shared/modals";
import { linkSearchMutation } from "../../graphql/advanceSearch.query";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import css from "../../../../advance-search/advance-search.module.scss";

const AdvanceSearchLink: React.FC = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(linkSearchMutation, {});
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
    course: null,
  });

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value);
  }, [selectorsState]);

  const createObjectMutation = () => {
    const filterArray = [];
    const programFilters = [];

    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    (selectorsState.curriculum || selectorsState?.program) &&
      filterArray.push({
        curriculum: {
          code: selectorsState?.curriculum ? { eq: selectorsState.curriculum } : undefined,
          program: selectorsState?.program ? { code: { eq: selectorsState.program } } : undefined,
        },
      });
    selectorsState.campus && programFilters.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && programFilters.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      programFilters.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && programFilters.push({ shiftId: { eq: selectorsState.shift } });

    if (programFilters.length > 0) {
      filterArray.push({
        program: {
          and: programFilters,
        },
      });
    }

    const filter: LinkFilterInput = {
      fields:
        selectorsState?.course || filterArray?.length
          ? {
              courseId: selectorsState?.course ? { eq: selectorsState.course } : undefined,
              course: filterArray?.length ? { and: filterArray } : undefined,
            }
          : undefined,
      pagination: {
        page: 1,
        size: 10,
      },
    };

    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter,
    };
    return variables;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createLinkFilter;
      if (dataItems.links.items.length > 0) {
        const idSearch = dataItems.id;
        const context = `${workspace}/${scenario}/${origin}`;
        const urlSearch = `/scheduler/editor/searchBy/link/${context}/?advance=${idSearch}`;
        history.push(urlSearch);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />

      <AdvancedSelectorsWrapper
        className={css.hasBottomSeparation}
        scenario={scenario}
        onChangeState={setSelectorsState}
      />

      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.actionButton}
          onClick={() => callUpdateHook()}
          disabled={error || !hasSomeFilter}
        >
          Buscar
        </Button>
      )}
    </>
  );
};

export default AdvanceSearchLink;
