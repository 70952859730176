import { useApolloClient } from "react-apollo";
import packageSearchQuery from "../graphql/packageSearch.query.graphql";
import { useState } from "react";
import { Maybe, Package, PackageFilterFieldsInput, PackagePage } from "@models/ISchema";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";

interface UsePaginatedPackagesProps {
  searchTerm?: string;
  scenarioId: string;
  originId: string;
  filterId?: string | string[];
  campusId?: string | number;
  fields?: Maybe<PackageFilterFieldsInput>;
  packagesFilterMethod?: (packages?: Package[]) => RegularOption[];
}

export const usePaginatedPackages = (props: UsePaginatedPackagesProps) => {
  const {
    searchTerm = "",
    originId,
    scenarioId,
    filterId,
    packagesFilterMethod,
    campusId,
    fields,
  } = props;
  const client = useApolloClient();
  const [prevSearchText, setPrevSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const queryOptions = (searchTerm = "", page = 1) => {
    const pageSize = 20;

    return {
      query: packageSearchQuery,
      variables: {
        scenarioId,
        originId,
        filterId,
        campusId,
        filter: {
          pagination: {
            page,
            size: pageSize,
            searchTerm,
          },
          fields,
        },
      },
    };
  };

  const getPackageOptions = async (searchTerm = "", page = 1) => {
    try {
      setIsLoading(true);

      const query = queryOptions(searchTerm, page);
      const { data } = await client.query(query);
      const packagePage = data?.cube?.packages as PackagePage;
      const packages = packagePage?.items || [];
      const pageInfo = packagePage?.pageInfo;

      return {
        options: packagesFilterMethod ? packagesFilterMethod([...packages]) : [...packages],
        pageInfo,
      };
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadPackages = async () => {
    const hasSearchText = prevSearchText === searchTerm;
    const newSearchPage = hasSearchText ? page + 1 : 1;

    setPrevSearchText(searchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await getPackageOptions(searchTerm, newSearchPage);

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  return {
    isLoading,
    loadPackages,
  };
};
