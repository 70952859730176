import React, { useEffect, useState } from "react";
import { isEmpty, map } from "ramda";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { Loading, Button, Heading } from "@foris/avocado-suite";
import { ContextEditLabel, useContextEditLabel } from "./ContextEditLabels";
import { IParams } from "../../../../models/IParams";
import Layout from "../../../../common/layout/Layout";
import HeaderLabels from "./HeaderLabels";
import SelectLabels from "./SelectLabels";
import ListLabels from "./ListLabels";
import useUpdateGroupLabel from "../hooks/useUpdateGroupLabel";
import css from "./editLabels.module.scss";

const EditLabels = () => {
  const { id, scenario, origin, workspace }: IParams = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const contextEdit = useContextEditLabel();
  const [loading, setLoading] = useState(false);
  const [updateGroupLabelsResponse, updateGroupLabels] = useUpdateGroupLabel(id, scenario, origin);
  const { labels, options, setLabels, setOptions } = contextEdit;
  const updateLabels = labels?.filter(label => label.isNew);
  const updateOptions = options?.filter(label => !label.value.isNew);
  const isUpdate = updateLabels?.length > 0 || updateOptions?.length > 0;

  const onSaveLabels = async () => {
    if (labels && isUpdate) {
      setLoading(true);
      updateGroupLabels(labels);
    }
  };

  useEffect(() => {
    if (!isEmpty(updateGroupLabelsResponse ?? {}) && loading) {
      if (updateGroupLabelsResponse?.commited) {
        const newLabels = updateGroupLabelsResponse.group.labels.map(value => ({
          ...value,
          isNew: false,
        }));
        if (updateOptions?.length) {
          const newOptions = map(
            value => ({
              label: value.label,
              value: { ...value.value, isNew: true },
            }),
            options ?? [],
          );
          setOptions(newOptions);
        }

        setLabels(newLabels);
        setLoading(false);
        history.push(`/scheduler/editor/group/${workspace}/${scenario}/${origin}/${id}`);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [updateGroupLabelsResponse]);

  return (
    <ContextEditLabel.Provider value={contextEdit}>
      <Layout contextSearch>
        {loading && <Loading />}
        <HeaderLabels onLoading={value => setLoading(value)} />
        {!loading && (
          <article className={css.editLabels}>
            <Heading type="h2" className={css.editLabels_title}>
              {t("edit-tag-manually.title")}
            </Heading>

            <SelectLabels
              className={css.editLabels_customSelect}
              label={t("edit-tag-manually.select-option.label")}
            />

            <ListLabels />

            <footer className={css.editLabels_actionsButton}>
              <Button
                variant="ghost"
                onClick={() => {
                  history.push(`/scheduler/editor/group/${workspace}/${scenario}/${origin}/${id}`);
                }}
              >
                {t("edit-tag-manually.btn-back")}
              </Button>
              <Button disabled={!isUpdate} onClick={onSaveLabels}>
                {t("edit-tag-manually.btn-save")}
              </Button>
            </footer>
          </article>
        )}
      </Layout>
    </ContextEditLabel.Provider>
  );
};

export default EditLabels;
