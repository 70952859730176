import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Select } from "@foris/avocado-suite";
import { useApolloClient } from "react-apollo";
import { QUERY_SELECT_COMPONENT } from "./graphql/changeComponent.queries";
import { CourseComponentPage, CourseComponent } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import css from "./searchComponent.module.scss";

const FIRST_SEARCH = 20;

interface ISearchComponentProps {
  courseId: any;
  sendValues: any;
  initValue: any;
  onDisabled?: (isDisabled: boolean) => void;
}

const SearchComponent: React.FC<ISearchComponentProps> = ({
  courseId,
  onDisabled,
  ...props
}: ISearchComponentProps) => {
  const { sendValues, initValue } = props;
  const client = useApolloClient();
  const { scenario }: IParams = useParams();
  const { t } = useTranslation();
  const [options, setOptions] = useState(null);
  const [selectValue, setSelectValue] = useState(initValue);
  const [loading, setLoading] = useState(false);

  const isDisabled = initValue && options?.length === 1;

  const serializeOptions = (component: CourseComponentPage) => {
    const CourseComponentOptions = [];
    component.items.forEach((optionComponent: CourseComponent) => {
      CourseComponentOptions.push({
        label: optionComponent.component.name,
        value: optionComponent.id,
      });
    });
    return CourseComponentOptions;
  };

  useEffect(() => {
    const queryOptions = () => {
      return {
        query: QUERY_SELECT_COMPONENT,
        variables: {
          scenarioId: scenario,
          filter: {
            fields: {
              courseId: {
                eq: courseId,
              },
            },
            pagination: {
              page: 1,
              size: FIRST_SEARCH,
            },
          },
        },
      };
    };

    const getDataOptions = async () => {
      try {
        setLoading(true);
        const query = queryOptions();
        const { data } = await client.query(query);
        const comOption = serializeOptions(data.data.courseComponents);
        setOptions(comOption);
        setLoading(false);
      } catch (error) {
        setOptions([]);
        setLoading(false);
      }
    };
    const getCacheDataOptions = async () => {
      try {
        setLoading(true);
        const query = queryOptions();
        const data = await client.readQuery(query);
        const comOption = serializeOptions(data.data.courseComponents);
        setOptions(comOption);
        setLoading(false);
      } catch (error) {
        getDataOptions();
      }
    };

    if (options === null) getCacheDataOptions();
  }, [client, scenario, courseId, options]);

  useEffect(() => {
    onDisabled?.(isDisabled);
  }, [isDisabled]);

  return (
    <div className={css.container}>
      {options && (
        <Select
          label={t("calendar-view.option-select.label")}
          disabled={isDisabled}
          isClearable
          isLoading={loading}
          className={css.container_selectValidate}
          value={selectValue}
          placeholder={t("calendar-view.option-select.placeholder")}
          options={options}
          onChange={(value: any) => {
            setSelectValue(value);
            sendValues(value);
          }}
        />
      )}
    </div>
  );
};

export default SearchComponent;
